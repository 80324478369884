<script setup>
import { computed, defineProps, ref } from 'vue'

import PollDialog from '@/components/journal/parent/poll/PollDialog.vue'

const props = defineProps({
  polls: Array
})

const isPollDialogVisible = ref(false)

const showPollDialog = () => {
  isPollDialogVisible.value = true
}

const hidePollDialog = () => {
  isPollDialogVisible.value = false
}

const canVote = computed(() => (
  props.polls.some((poll) => !poll.selected)
))
</script>

<template>
  <div class="card">
    <PollDialog
      v-if="isPollDialogVisible"
      :polls="polls"
      @close="hidePollDialog"
    />
    <div class="card-content">
      <div class="header">
        <div>
          <h2 class="header-text">
            Голосование
          </h2>
        </div>
        <div>
          <button @click="showPollDialog" class="button green no-margin vote-button">
            <template v-if="canVote">Проголосовать</template>
            <template v-else>Посмотреть результаты</template>
          </button>
        </div>
      </div>
      <div class="detail">
        <ul class="poll-list">
          <li
            v-for="poll in polls"
            :key="poll.id"
            :class="['poll-list-item', {'voted': !!poll.selected}]"
            @click="showPollDialog"
          >
            <span class="poll-list-item-icon">
              <font-awesome-icon v-if="poll.selected" icon="check-square" fixedWidth />
              <font-awesome-icon v-else icon="angle-right" fixedWidth />
            </span>
            <span class="poll-list-item-text">
              {{ poll.text }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  width: 768px;

  margin: 1em 0;
  padding: 1em;

  border-radius: 1em;

  background-color: var(--medium-overlay);
}

.card-content {
  position: relative;
  z-index: 0;

  border-radius: 0.5em;
  overflow: hidden;
}

.header {
  padding: 2em;

  color: white;
  background-color: var(--green);
  background-image: linear-gradient(60deg, transparent, transparent 50%, rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0.1));

  text-shadow: 0px 1px 1px var(--shadow);

  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0 0 10px var(--shadow);
}

.header-text {
  margin: 0;

  font-size: 2em;
}

.header-subtext {
  margin-top: 0.3em;
}

.vote-button {
  box-shadow: 0 1px 5px var(--shadow);
}

.detail {
  padding: 2em;

  background-color: white;
  background-image: linear-gradient(to top, transparent, transparent 50%, rgba(0, 0, 0, 0.05) 80%, rgba(0, 0, 0, 0.05));
  background-size: auto 200px;
  background-repeat: no-repeat;
}

.detail-columns {
  display: flex;
}

.detail-header {
  margin-top: 0;
}

.poll-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.poll-list-item {
  padding: 0.3em 0;
  cursor: pointer;
}

.poll-list-item .poll-list-item-icon {
  margin-right: 0.5em;
  color: var(--medium-gray);
}

.poll-list-item.voted .poll-list-item-icon {
  color: var(--green-darker);
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;

    padding: 0;
    margin: 0;

    border-radius: 0;
  }

  .card-content {
    border-radius: 0;
  }
}
</style>
