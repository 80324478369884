<script setup>
import { computed, defineProps, ref } from 'vue'
import { useToast } from 'vue-toastification'

import api from '@/api'
import eventBus from '@/eventBus'
import { getErrors } from '@/errors'
import { pluralize } from '@/utils'

const toast = useToast()

const props = defineProps({
  poll: Object
})

const submitting = ref(null)

const canVote = computed(() => (
  !props.poll.selected && !props.poll.is_locked
))

const totalVotes = computed(() => {
  if (!props.poll.selected) return null

  return props.poll.performances.reduce((curr, performance) => curr + performance.votes, 0)
})

const vote = (performance) => {
  if (!canVote.value) return

  submitting.value = performance.id

  api.post(`/polls/${props.poll.id}/vote/`, { performance: performance.id })
    .then((response) => {
      toast.success('Ваш голос принят')
      eventBus.emit('updatePolls', response.data)
    }, (error) => {
      toast.error(getErrors(error).detail)
    })
    .then(() => {
      submitting.value = null
    })
}
</script>

<template>
  <div
    :class="[
      'poll',
      {'voted': !!poll.selected},
      {'locked': poll.is_locked},
      {'can-vote': canVote}
    ]"
  >
    <div class="poll-header">
      <div class="poll-header-icon">
        <font-awesome-icon
          v-if="poll.is_locked"
          icon="lock"
          style="color: var(--red-darker)"
        />
      </div>
      <div class="poll-header-text">
        {{ poll.text }}
      </div>
    </div>
    <div class="poll-option-list">
      <div
        v-for="performance in poll.performances"
        :key="performance.id"
        :class="['poll-option', {'selected': performance.id === poll.selected}]"
        @click="vote(performance)"
      >
        <div class="poll-option-icon">
          <font-awesome-icon
            v-if="performance.id === poll.selected"
            :icon="['fas', 'check-circle']"
            style="color: var(--green-darker)"
          />
          <font-awesome-icon
            v-else-if="performance.id === submitting"
            :icon="['fas', 'circle-notch']"
            spin
          />
          <font-awesome-icon
            v-else-if="canVote"
            :icon="['far', 'circle']"
          />
        </div>
        <div class="poll-option-text">
          {{ performance.name }}
        </div>
        <div class="poll-option-results">
          <span v-if="poll.selected">
            {{ Math.round(performance.votes / totalVotes * 100) }}%
          </span>
        </div>
        <div
          class="poll-option-line"
          :style="{
            width: poll.selected ? `${Math.round(performance.votes / totalVotes * 100)}%` : 0
          }"
        ></div>
      </div>
    </div>
    <div class="total-votes">
      {{ `${totalVotes} ${pluralize(totalVotes, 'голос', 'голоса', 'голосов')}` }}
    </div>
  </div>
</template>

<style scoped>
.poll {
  margin-bottom: 2em;
}

.poll.locked {
  opacity: 0.5;
}

.poll-header {
  display: flex;
  gap: 0.6em;

  margin-bottom: 0.5em;
  font-weight: bold;
}

.poll-header-icon {
  width: 1em;
}

.poll-header-text {

}

.poll-option-list {
  margin-bottom: 0.5em;
}

.poll-option {
  display: flex;
  gap: 0.6em;

  padding: 0.8em 0.5em;
  margin: 0 -0.5em;

  position: relative;
}

.poll.can-vote .poll-option {
  cursor: pointer;
}

.poll:not(.locked) .poll-option:hover {
  background-color: var(--light-overlay);
}

.poll-option-icon {
  width: 1em;
}

.poll-option-text {
  flex: 1;
}

.poll-option-results {
  width: 3em;
  text-align: right;
}

.poll-option-line {
  position: absolute;
  bottom: 0.5em;
  left: 2.1em;

  height: 2px;

  background-color: var(--green);

  transition: width 0.2s;
}

.total-votes {
  text-align: right;
  visibility: hidden;
}

.poll.voted .total-votes {
  visibility: visible;
}
</style>
