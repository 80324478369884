<template>
  <div class="journal-page">
<!--    <ViberDialog-->
<!--      v-if="isViberDialogVisible"-->
<!--      @close="isViberDialogVisible = false"-->
<!--    />-->
    <PushNotifications :user="user" />
    <div
      v-if="!isPending && polls && polls.length > 0"
      class="card-list"
    >
      <PollCard :polls="polls"/>
    </div>
    <div
      v-if="!isPending && children && children.length > 0 && concerts && concerts.length > 0"
      class="card-list"
    >
      <ConcertCard
        v-for="concert in concerts"
        :concert="concert"
        :children="children"
        :key="concert.id"
        @purchaseTicket="fetchData"
      />
    </div>
    <div
      v-if="!isPending && children && children.length > 0 && items && items.length > 0"
      class="card-list"
    >
      <MerchCard
        v-for="item in items"
        :item="item"
        :children="children"
        :key="item.id"
        @purchase="fetchData"
      />
    </div>
    <BaseSpinner v-if="isPending"/>
    <template v-else-if="children && children.length > 0">
      <div v-if="children.length > 1" class="tab-links child-links">
        <a
          v-for="child in children"
          :key="child.id"
          :class="[
            'tab-link',
            'child-link',
            {'active': child.id === selectedChildID},
            {'inactive': !child.is_active}
          ]"
          @click="selectedChildID = child.id"
        >
          {{ child.first_name }}
        </a>
      </div>
      <div v-if="selectedChild" class="card-list">
        <ChildCard
          :child="selectedChild"
          :key="selectedChild.id"
        />
      </div>
    </template>
    <div v-else-if="children" class="alert-wrapper">
      <BaseAlert color="warning">
        <div>Ваши дети ещё не добавлены в базу.</div>
        <div>Если вы уверены, что это ошибка, обратитесь к администратору.</div>
      </BaseAlert>
    </div>
    <div v-if="texts" class="parent-controls">
      <a :href="texts.agreement" class="button no-background">Договор оферты</a>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, onBeforeUnmount, provide, ref } from 'vue'
import { useToast } from 'vue-toastification'
import axios from 'axios'

import ChildCard from '@/components/journal/parent/child/ChildCard.vue'
import ConcertCard from '@/components/journal/parent/concert/ConcertCard.vue'
import MerchCard from '@/components/journal/parent/merch/MerchCard.vue'
import PollCard from '@/components/journal/parent/poll/PollCard.vue'
import PushNotifications from '@/components/journal/PushNotifications.vue'
// import ViberDialog from '@/components/journal/parent/child/ViberDialog.vue'

import api from '@/api'
import { getErrors } from '@/errors'
import eventBus from '@/eventBus'

const toast = useToast()

defineProps(['user'])

const isPending = ref(false)

const children = ref(null)
const concerts = ref(null)
const items = ref(null)
const polls = ref(null)
const texts = ref(null)

provide('texts', texts)

const fetchData = () => {
  isPending.value = true

  return axios.all([
    api.get('/children/'),
    api.get('/concerts/'),
    api.get('/merch/items/'),
    api.get('/polls/'),
    api.get('/parent-journal/')
  ])
    .then(([_children, _concerts, _items, _polls, _texts]) => {
      children.value = _children.data
      concerts.value = _concerts.data
      items.value = _items.data
      polls.value = _polls.data
      texts.value = _texts.data
    }, error => {
      toast.error(getErrors(error).detail)
    })
    .then(() => {
      isPending.value = false
    })
}

const selectedChildID = ref(null)

const selectedChild = computed(() => {
  return (
    selectedChildID.value &&
    children.value &&
    children.value.find(child => child.id === selectedChildID.value)
  )
})

onMounted(() => {
  fetchData().then(() => {
    if (children.value && children.value.length > 0) {
      selectedChildID.value = children.value[0].id
    }
  })
})

onMounted(() => {
  eventBus.on('buySubscription', fetchData)
})

onBeforeUnmount(() => {
  eventBus.off('buySubscription', fetchData)
})

const updatePolls = (newPolls) => {
  polls.value = newPolls
}

onMounted(() => {
  eventBus.on('updatePolls', updatePolls)
})

onBeforeUnmount(() => {
  eventBus.off('updatePolls', updatePolls)
})

const pollsRefreshing = ref(false)

const refreshPolls = () => {
  if (pollsRefreshing.value) return

  pollsRefreshing.value = true

  api.get('/polls/')
    .then((response) => {
      polls.value = response.data
    }, (error) => {
      toast.error(getErrors(error).detail)
    })
    .then(() => {
      pollsRefreshing.value = false
    })
}

let refreshPollsInterval

onMounted(() => {
  refreshPollsInterval = setInterval(refreshPolls, 10000)
})

onBeforeUnmount(() => {
  clearInterval(refreshPollsInterval)
})

// const isViberDialogVisible = ref(false)

// const fetchIsViberRegistered = () => {
//   api.get('/bots/viber/is-registered/')
//     .then((response) => {
//       isViberDialogVisible.value = !response.data.is_registered
//     })
// }

// onMounted(() => {
//   this.fetchIsViberRegistered()
// })
</script>

<style scoped>
.journal-page {
  position: relative;

  flex: 1;

  display: flex;
  flex-direction: column;
}

.child-link.inactive {
  color: var(--medium-gray);
}

.child-link.active {
  color: var(--dark-gray);
}

.card-list {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.parent-controls {
  position: relative;

  margin-bottom: 2em;

  align-self: center;

  display: flex;
  align-items: center;
}

.alert-wrapper {
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
