<script setup>
import { defineEmits, defineProps } from 'vue'

import PollForm from '@/components/journal/parent/poll/PollForm.vue'

defineProps({
  polls: Array
})

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <div class="dialog-box">
      <div class="dialog-header">
        <div class="dialog-header-text">Голосование</div>
        <BaseCloseButton @click="close" />
      </div>
      <div class="dialog-content poll-dialog-content">
        <PollForm
          v-for="poll in polls"
          :key="poll.id"
          :poll="poll"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.dialog-box {
  width: 35em;
}

.poll-dialog-content {
  max-height: 70vh;
  overflow: auto;
}

@media screen and (max-width: 600px) {
  .poll-dialog-content {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 768px) {
  .dialog-box {
    width: 95%;
  }

  .poll-dialog-content {
    max-height: unset;
  }
}
</style>
